<template>
<div class="row">
<div class="col-md-12">

        <div class="col-md-8 offset-md-2">
        
        <span class="h4">Nueva Encuesta</span> <br>
        <span class="text-secondary">Por favor complete el formulario a continuación como primer paso para el regustro de encuetas</span>

        <div class="card card-body shadow-sm mt-3">

        <div v-if="mensaje" class="alert alert-danger">
           <i class="fas fa-exclamation mr-2"></i>  Error al almacenar los datos por favor intente de nuevo 
        </div>

        <form v-on:submit.prevent="guardarEncuesta">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="tituloencuesta">Título de la Encuesta:</label>
                    <input type="text" required class="form-control"  v-model="form.tituloencuesta">
                </div>
            </div>
  
             <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="slug">Descripción:</label>
                    <textarea name="descripcionencuesta" id="descripcionencuesta" v-model="form.descripcionencuesta" class="form-control"  cols="30" rows="5"></textarea>
                </div>
            </div>
   
 
            <button type="submit" :disabled="!estado" class="mt-3 btn btn-sm btn-outline-primary">
                <div v-if="!estado" class="spinner-border spinner-border-sm mr-3" role="status">
                     <span class="sr-only">cargando...</span>
                </div>
                <i class="fas fa-save"></i> Guardar y continuar
            </button>
        </form>

        </div>

        </div>

</div>
</div>
</template>
<script>

import axios from 'axios'
import router from '../../router'

export default {
    name: 'nuevoencuesta',
    data (){
        return {
            form: {
                tituloencuesta: '',
                slug: '',
                descripcionencuesta: '',
            },
            mensaje: false,
            estado: true
        }
    }, 
    methods:{
        guardarEncuesta(){
                
                this.estado = false

                const url = "api/crearencuesta"
                axios.post(url, { data: this.form } , { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
                .then( (response) => {
                   
                   if(response.data !== 0){
                       this.$router.push({ name: 'infoencuesta', params: { id: response.data  } })
                   }
                    this.mensaje = false

                }).catch((error) => {

                    this.mensaje = true
                    this.estado = true

                });
        }
    },
    mounted(){

    }
}
</script>
